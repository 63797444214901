import {
    Button,
    Card,
    CardContent, Chip,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem, Tooltip, Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import QrCodeIcon from "@mui/icons-material/QrCode";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import BuildIcon from "@mui/icons-material/Build";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import DeleteIcon from "@mui/icons-material/Delete";
import {QRCodeSVG} from "qrcode.react";
import {useContext, useState, Fragment} from "react";
import {useDeleteVariantBaseMutation} from "../../core/reducers/variant";
import {NewVariantTestModal} from "./NewVariantTestModal";
import clipboardCopy from "clipboard-copy";
import {SnackBarContext} from "../../core/context/snackBarProvider";

export const VariantItem = ({item, variant}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openQR, setOpenQR] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openBuild, setOpenBuild] = useState(false);

    const [deleteVariant, deleteVariantResponse] = useDeleteVariantBaseMutation();


    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteOpen = () => {
        setOpenDelete(true);
        handleMenuClose();
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
    };
    const handleDelete = () => {
        debugger
        deleteVariant({variant: variant, id: item.hasOwnProperty('id')?item.id:item.bundleId})
        setOpenDelete(false);
    };

    const handleQROpen = () => {
        setOpenQR(true);
    };

    const handleQRClose = () => {
        setOpenQR(false);
    };


    const handleCopyClick = (text) => {
        clipboardCopy(text);

        addAlert({text: 'copied', type: 'success'})

        return false;
    };

    const [openCreate, setOpenCreate] = useState(false);

    const qr = "clockmaker-dev://?label=" + encodeURI(`disableSynchro=true&preloadBundles=true&variantBundle=${item?.bundleId}&variantBalance=${item.balanceLink}`)

    const link = document.location.origin + document.location.pathname + '?filterType=' + item?.type + '&filterText=' + item?.id

    const analytics_link = 'https://analytics.belka-games.com/mcm#dashboard=cm-custom-ab-tests-%3Fp_user_type%3DTotal%26p_tier%3DTotal%26p_user_type_paid%3DTotal%26p_traffic_type%3DTotal%26p_Payment_cohort%3DTotal%26p_w25217_N%3D1%26p_w25255_Trick%3DTotal%26p_w25256_Trick%3DTotal%26p_w25257_Trick%3DTotal%26p_w25258_Trick%3DTotal%26p_w25259_Booster%3DTotal%26p_w25260_Booster%3DTotal%26p_w25261_Booster%3DTotal%26p_w25262_Booster%3DTotal%26p_test_name%3D%255B20231110_TPhard_vs_TPeasy%255D%2520-%2520%D0%A3%D1%81%D0%BB%D0%BE%D0%B6%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5%2520%D1%83%D1%80%D0%BE%D0%B2%D0%BD%D0%B5%D0%B9%252020-%D0%BA%D0%B8%2520%D1%81%2520%D1%82%D0%B0%D0%B9%D0%BC%D0%BF%D1%80%D0%B5%D1%81%D1%81%D0%B8%D0%BD%D0%B3%D0%BE%D0%BC.%2520%D0%90%D0%92-%D1%82%D0%B5%D1%81%D1%82';

    const new_campaign_link = document.location.origin + "/creator/campaigns?env=liveops&mode=variants&type="+item?.type+"&bundleId="+item?.bundleId
    const new_ab_link = document.location.origin + "/creator/abtestsmini?env=liveops&mode=variants&type="+item?.type+"&bundleId="+item?.bundleId

    const statusColors = {
        'READY':'warning',
        'CREATING':'error',
        'BUILT':'success',
    }

    const disabledStatus = 'creating'

    return <>
        <Card className="campaign-item" style={{marginBottom: '3px'}}>
            <CardContent>


                <div className="campaign-item__body" >
                    <div >
                        <div className="campaign-item__header">

                            <div className="campaign-item__name">

                                <div >


                                    {variant=='base'&&<span >{item.bundleId}</span>}
                                    {variant=='test'&&<span >{item.id}</span>}

                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {variant=='base'&&<Button variant="outlined" onClick={() => setOpenCreate(true)}>New test</Button>}
                        {variant=='test'&&<Chip variant={'outlined'} label={item.status}  color={statusColors.hasOwnProperty(item.status)?statusColors[item.status]:'primary'}/>}

                        <Tooltip title={"Edit Balance"}>
                            <IconButton disabled={item.balanceLink==null||item.status?.toLowerCase()==disabledStatus} href={item.balanceLink} target={"_blank"}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <IconButton disabled={item.status?.toLowerCase()==disabledStatus} onClick={handleQROpen}>
                            <QrCodeIcon />
                        </IconButton>

                        <Tooltip title={"Knowledge Base"}>
                            <IconButton disabled={item.status?.toLowerCase()==disabledStatus} href={item.docLink} target={"_blank"}>
                                <MenuBookIcon />
                            </IconButton>
                        </Tooltip>

                        <IconButton disabled={item.status?.toLowerCase()==disabledStatus} onClick={handleMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {variant=='test'&&<Fragment>
                                <MenuItem onClick={() => {handleCopyClick(link)}}>
                                    <ShareIcon style={{ marginRight: '8px' }} /> Share
                                </MenuItem>
                                <MenuItem onClick={()=>setOpenBuild(true)}>
                                    <BuildIcon style={{ marginRight: '8px' }} /> Build
                                </MenuItem>
                                <MenuItem component={"a"} target={"_blank"} href={new_campaign_link}>
                                    <AddCircleOutlineIcon style={{ marginRight: '8px' }} /> New Campaign
                                </MenuItem>
                                <MenuItem component={"a"} href={new_ab_link}>
                                    <AddCircleOutlineIcon style={{ marginRight: '8px' }} /> New AB Test
                                </MenuItem>
                                <MenuItem component={"a"} href={analytics_link} href={analytics_link} target={"_blank"}>
                                    <BarChartIcon style={{ marginRight: '8px' }} /> Analytics
                                </MenuItem>
                            </Fragment>}
                            <MenuItem onClick={handleDeleteOpen}>
                                <DeleteIcon style={{ marginRight: '8px' }} /> Delete
                            </MenuItem>
                        </Menu>
                    </div>
                </div>

            </CardContent>
        </Card>

        {/* QR Code Popup */}
        <Dialog open={openQR} onClose={handleQRClose}>
            <DialogTitle>QR Code<br />{item.bundleId}</DialogTitle>
            <DialogContent>
                <QRCodeSVG size={256} leve={'M'} value={qr} />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleQRClose} variant={'contained'}>Close</Button>
            </DialogActions>
        </Dialog>

        {/* Delete Confirmation Popup */}
        <Dialog open={openDelete} onClose={handleDeleteClose}>
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete <b>{item.bundleId}</b>?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteClose} variant={'outlined'}>Cancel</Button>
                <Button onClick={handleDelete} color="primary" variant={'contained'}>Yes</Button>
            </DialogActions>
        </Dialog>

        {/* Build Popup */}
        <Dialog open={openBuild} onClose={() => {setOpenBuild(false)}}>
            <DialogTitle>
                Build info
                <br /><small>click to copy</small>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>

                    <div>Name<br />
                        <Chip sx={{mb:1}} label={item.id} variant="outlined" onClick={()=>handleCopyClick(item.id)} />
                    </div>
                    <div>Bundle build<br />
                        <Chip sx={{mb:1}} label="http:///// some link" variant="outlined" onClick={()=>handleCopyClick('build')} />
                    </div>
                    <div>Branch<br />
                        <Chip sx={{mb:1}} label={'branch'} variant="outlined" onClick={()=>handleCopyClick(item.balanceLink)} />
                    </div>
                    <div>Balance link<br />
                        <Chip sx={{mb:1}} label={item.balanceLink} variant="outlined" onClick={()=>handleCopyClick(item.balanceLink)} />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpenBuild(false)} variant={'outlined'}>Close</Button>
            </DialogActions>
        </Dialog>

        {openCreate&&<NewVariantTestModal openCreate={openCreate} setOpenCreate={setOpenCreate} base={item} />}

    </>
}