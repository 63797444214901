import {Typography} from "@mui/material";
import Loading from "../Loading";
import {VariantItem} from "./VariantItem";
import {useVariantBaseQuery} from "../../core/reducers/variant";

export const TestVariants = ({filterText, filterType}) => {


    const {
        data: variantsTest,
        isLoading: isLoadingTest,
        isError: isErrorTest,
        error: errorTest
    } = useVariantBaseQuery({variant:'test',type:filterType},{pollingInterval: 5000})


    return <div className="campaign-group">

        <div className="campaign-type-title">
            <Typography sx={{p:0.5}}><strong>Test variants</strong></Typography>

        </div>

        <div className="campaigns-list variants">
            <Loading isLoading={isLoadingTest} isError={isErrorTest} error={errorTest}>
                {variantsTest!=undefined && variantsTest.filter(item => filterText==''||(item.id!=null&&item.id.indexOf(filterText)>=0)).map((item) => (<VariantItem variant={"test"} item={item} />))}
            </Loading>

        </div>
    </div>
}