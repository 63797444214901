import {Checkbox, FormControlLabel, FormHelperText, Grid, TextField} from "@mui/material";
import {Fragment, useState} from "react";
import {MODE} from "../../Campaign/campaignMode";
import BasicDateTimePicker from "../../Utils/BasicDateTimePicker";
import {Alert} from "@mui/lab";

export default function BasicStep({
                                      currentAbTest,
                                      mode,
                                      errorFields,
                                      handleChange,
                                      handleDateChange,
                                  }) {

    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

    const regexp_validate_name = /^(\d{4}_\d{2}_\d{2}_AB_[A-Za-z0-9_]+|Config_[A-Za-z0-9_]+)$/
    const regexp_validate_name2 =  /^\d{8}_AB_[A-Za-z0-9_]+$/

    return <Fragment>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    required
                    disabled={mode === MODE.UPDATE}
                    id="abTestId"
                    name="abTestId"
                    label="AB Test ID"
                    error={errorFields.includes('abTestId') ||errorFields.includes('regexp') || errorFields.includes('abTestIdEmpty')}
                    fullWidth
                    value={currentAbTest.abTestId}
                    variant="outlined"
                    onChange={handleChange}
                />
                {errorFields.includes('regexp') && <FormHelperText style={{color: "red"}}>
                    AB Test ID must match the given regexp: {new RegExp(regexp_validate_name2).toString()}. See <a href={"https://belka-games.atlassian.net/wiki/spaces/BAC/pages/4008345627/Mephisto+Naming+Rules"} target={"_blank"}>Mephisto Naming Rules</a>.
                </FormHelperText>}
                {errorFields.includes('abTestId') && <FormHelperText style={{color: "red"}}>
                    This id is already taken
                </FormHelperText>}
                {currentAbTest.abTestId?.toLowerCase().includes('clone') && <FormHelperText style={{color: "red"}}>
                    Change AB Test ID, it shouldn't contain "Clone"
                </FormHelperText>}
            </Grid>
            <Grid item>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={6}>
                        <BasicDateTimePicker
                            label="From (GMT+3)"
                            name="from"
                            value={currentAbTest.from}
                            onChange={handleDateChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicDateTimePicker
                            label="Till (GMT+3)"
                            name="till"
                            value={currentAbTest.till}
                            onChange={handleDateChange}
                        />
                        {errorFields.includes('date') &&
                            <FormHelperText style={{color: "red"}}>The end date must be after the start
                                date</FormHelperText>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="abTestLink"
                    name="link"
                    label="Link"
                    fullWidth
                    value={currentAbTest.metaData?.link}
                    variant="outlined"
                    error={currentAbTest.metaData?.link && !/^https:\/\/(?:app\.)?asana\.com\/.*$/.test(currentAbTest.metaData?.link)}
                    helperText={
                        currentAbTest.metaData?.link && !/^https:\/\/(?:app\.)?asana\.com\/.*$/.test(currentAbTest.metaData?.link)
                            ? "Link must be a valid URL to asana.com"
                            : ""
                    }
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} pt={1}>
                <Alert severity="info">Помните, что From и Till – это даты набора игроков в когорты. По наступлению Till
                    игроки останутся в них, не потеряют свои когорты.</Alert>
            </Grid>
            <Grid item xs={12} style={{paddingTop: 3}} display={'flex'} justifyContent={'center'}>
                <span style={{textDecoration: 'underline dotted', cursor: 'pointer'}}
                      onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}>
                    advanced settings
                </span>
            </Grid>
            {showAdvancedSettings &&
                <Fragment>
                    <Grid item xs={12}>
                        <TextField
                            id="groupId"
                            name="groupId"
                            label="Group ID"
                            value={currentAbTest.groupId}
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="priority"
                            name="priority"
                            label="Priority"
                            error={errorFields.includes('priority')}
                            value={currentAbTest.priority}
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox />}
                            id="reassignCohort"
                            label="Reassign Cohort"
                            name='reassignCohort'
                            checked={currentAbTest.reassignCohort}
                            onChange={handleChange}
                        />
                    </Grid>
                </Fragment>}
        </Grid>
    </Fragment>
}