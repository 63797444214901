import {
    Box,
    Grid,
    List,
    ListItemButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";

class AudienceForSave {
    includeAll = [];
    includeAny = [];
    excludeAll = [];
    excludeAny = [];
    numberRange = [];
    versionRange = [];
    offerTypeInactiveRule = [];
    offerInactiveRule = [];
    offerNotInProfile = [];
    offerInProfile = [];
    profileOffset = [];
}

class NumericRange {
    parameter: '';
    from: '';
    to: '';
}

export const getCohortsSection = (currentAbTest) => {
    return (
        <Grid xs={12}>
            <Table>
                <TableHead>
                    <TableRow >
                        <TableCell>Id</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Limit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentAbTest.cohorts.map(cohort => {
                        return <TableRow>
                            <TableCell>{cohort.id}</TableCell>
                            <TableCell>{cohort.active ? 'active' : 'inactive'}</TableCell>
                            <TableCell>{cohort.weight}</TableCell>
                            <TableCell>{cohort.limit}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </Grid>
    )
}

export const getAudienceSection = (audience, currentCampaign) => {
    return (
        <Grid xs={12}>
            <Typography component="h7" variant="h7">
                <Box pl={4} pt={3}>
                    {audience.name}
                </Box>
            </Typography>
            <List id='list' style={{width: '100%'}}>
                {getAudienceParameters(audience.name, currentCampaign).map(paramSet => {
                    return (
                        <ListItemButton>
                            <Grid container xs={12}>
                                <Grid item xs={3} pr={1}>
                                    <Box display={'flex'} justifyContent={'start'}>
                                        {paramSet?.parameterData?.parameterName}
                                    </Box>
                                </Grid>
                                {paramSet.hasOwnProperty('fromIncl') || paramSet.hasOwnProperty('toIncl') ?
                                    <Grid item xs={9} pl={1} xsOffset={1}>
                                        <Box display={'flex'} justifyContent={'start'}>
                                            {paramSet.fromIncl || paramSet.fromIncl === null ? '>= ' : '> '}{paramSet.paramFromValue ? paramSet.paramFromValue : '\u221E'} , {paramSet.toIncl || paramSet.toIncl === null ? '<= ' : '< '}{paramSet.paramToValue ? paramSet.paramToValue : '\u221E'}
                                        </Box>

                                    </Grid>
                                    :
                                    <>
                                        {getValueToDisplay(paramSet)}
                                    </>
                                }
                            </Grid>


                        </ListItemButton>
                    )
                })}
            </List>
        </Grid>

    )
}

function getAudienceParameters(audienceName, currentCampaign) {
    return currentCampaign.audiences.filter(audience => audience.name === audienceName)[0].parameters;
}

function getValueToDisplay(paramSet) {
    if (['offerInProfile','offerNotInProfile','offerTypeInactiveRule','offerInactiveRule'].includes(paramSet.operator)) { // paramSet.operator === "offerInProfile" || paramSet.operator === "offerNotInProfile") {
        return <Grid item xs={9} pl={1}>
            <Box display={'flex'} justifyContent={'start'}>
                {paramSet.paramValue ? paramSet.paramValue.toString() : 'Null'}
            </Box>
        </Grid>
    } else if (paramSet.paramValue) {
        return <>
            <Grid item xs={2} pl={1}>
                <Box display={'flex'} justifyContent={'start'}>
                    {paramSet.operator}
                </Box>

            </Grid>
            <Grid item xs={7} pl={1}>
                <Box display={'flex'} justifyContent={'start'}>
                    {paramSet.paramValue ? ( Array.isArray(paramSet.paramValue) ? paramSet.paramValue.join(', ') : paramSet.paramValue.toString()  ) : 'Null'}
                </Box>
            </Grid>
        </>
    } else if (paramSet.offsetValue) {
        return <>
            <Grid item xs={8} pr={0.5}>
                <Box display={'flex'} justifyContent={'start'} overflow={'hidden'}>
                    {paramSet.offerId ? paramSet.offerId.toString() : 'Null'}
                </Box>
            </Grid>
            <Grid item xs={1} pl={0.5}>
                <Box display={'flex'} justifyContent={'start'}>
                    {paramSet.offsetValue ? paramSet.offsetValue.toString() : 'Null'}
                </Box>
            </Grid>
        </>

    }
}

export function getListItem(fieldName, fieldDValue) {
    return (
        <ListItemButton>
            <Grid item xs={5} pr={1}>
                <Box display={'flex'} justifyContent={'end'}>
                    {fieldName}
                </Box>
            </Grid>
            <Grid item xs={7} pl={1}>
                {fieldDValue !== undefined && fieldDValue !== null ? fieldDValue.toString(): "N/A"}
            </Grid>
        </ListItemButton>
    )
}

export function prepareParametersForSave(audienceForSave, parameterSet, handleOffers = false) {
    audienceForSave = Object.keys(audienceForSave).length === 0 ? new AudienceForSave() : audienceForSave;

    if (parameterSet.parameterData?.allowedRules?.includes('VERSION_RANGE')) {
        // if (!audienceForSave.versionRange[parameterSet.parameterData.parameterName]) {
        //     audienceForSave.versionRange[parameterSet.parameterData.parameterName] = new NumericRange()
        // }
        let numericRange = {
            parameter: parameterSet.parameterData.parameterName,
            from: parameterSet.paramFromValue,
            to: parameterSet.paramToValue,
            fromIncluded: parameterSet.fromIncl,
            toIncluded: parameterSet.toIncl
        }
        audienceForSave.versionRange.push(numericRange)

        // audienceForSave.versionRange[parameterSet.parameterData.parameterName].parameter = parameterSet.parameterData.parameterName;
        // audienceForSave.versionRange[parameterSet.parameterData.parameterName].from = parameterSet.paramFromValue;
        // audienceForSave.versionRange[parameterSet.parameterData.parameterName].to = parameterSet.paramToValue;
        // audienceForSave.versionRange[parameterSet.parameterData.parameterName].fromIncluded = parameterSet.fromIncl;
        // audienceForSave.versionRange[parameterSet.parameterData.parameterName].toIncluded = parameterSet.toIncl;

    } else if (parameterSet.parameterData?.allowedRules?.includes('NUMBER_RANGE')) {
        // if (!audienceForSave.numberRange[parameterSet.parameterData.parameterName]) {
        //     audienceForSave.numberRange[parameterSet.parameterData.parameterName] = new NumericRange()
        // }
        let numberRange = {
            parameter: parameterSet.parameterData.parameterName,
            from: parameterSet.paramFromValue,
            to: parameterSet.paramToValue,
            fromIncluded: parameterSet.fromIncl,
            toIncluded: parameterSet.toIncl
        }
        audienceForSave.numberRange.push(numberRange)
        // audienceForSave.numberRange[parameterSet.parameterData.parameterName].parameter = parameterSet.parameterData.parameterName;
        // audienceForSave.numberRange[parameterSet.parameterData.parameterName].from = parameterSet.paramFromValue;
        // audienceForSave.numberRange[parameterSet.parameterData.parameterName].to = parameterSet.paramToValue;
        // audienceForSave.numberRange[parameterSet.parameterData.parameterName].fromIncluded = parameterSet.fromIncl;
        // audienceForSave.numberRange[parameterSet.parameterData.parameterName].toIncluded = parameterSet.toIncl;

    } else if (
        parameterSet.parameterData?.allowedRules?.includes('INCLUDE') ||
        parameterSet.parameterData?.allowedRules?.includes('INCLUDE_ALL') ||
        parameterSet.parameterData?.allowedRules?.includes('INCLUDE_ANY') ||
        parameterSet.parameterData?.allowedRules?.includes('EXCLUDE_ANY') ||
        parameterSet.parameterData?.allowedRules?.includes('EXCLUDE_ALL') ||
        parameterSet.parameterData?.allowedRules?.includes('EXCLUDE')
    ) {
        if (!audienceForSave[parameterSet.operator]?.[parameterSet.parameterData.parameterName]) {
            audienceForSave[parameterSet.operator].push({
                parameter: parameterSet.parameterData.parameterName,
                values: typeof parameterSet.paramValue === 'string' ? parameterSet.paramValue.split(',') :
                    parameterSet.paramValue
            })
        }
    } else if (parameterSet.parameterData?.allowedRules?.includes('OFFER_INACTIVE')) {
        audienceForSave.offerInactiveRule.push({
            parameter: 'offer_inactive',
            offerIds: parameterSet.paramValue
        })
    } else if (parameterSet.parameterData?.allowedRules?.includes('TYPE_INACTIVE')) {
        audienceForSave.offerTypeInactiveRule.push({
            parameter: 'type_inactive',
            types: parameterSet.paramValue
        })
    } else if (parameterSet.parameterData?.allowedRules?.includes('OFFER_IN_PROFILE')) {
        audienceForSave.offerInProfile.push({
            parameter: 'offers_in_profile',
            offerId: parameterSet.paramValue
        })
    } else if (parameterSet.parameterData?.allowedRules?.includes('OFFER_NOT_IN_PROFILE')) {
        audienceForSave.offerNotInProfile.push({
                parameter: 'offers_not_in_profile',
                offerId: parameterSet.paramValue
        })
    } else if (parameterSet.parameterData?.allowedRules?.includes('PROFILE_OFFSET')) {
        let paramName = parameterSet.parameterData.parameterName
        audienceForSave.profileOffset.push({
            offerId: parameterSet.offerId,
            offset: parameterSet.offsetValue,
            parameter: paramName
        })
    }

    checkOffersInAudience(audienceForSave)

    if (handleOffers) {
        _prepareOffers(audienceForSave, parameterSet);
    }

    return audienceForSave;
}

function checkOffersInAudience(audienceForSave) {

    if (!audienceForSave.hasOwnProperty("profileOffset")) {
        audienceForSave.profileOffset = [];
    }
    if (!audienceForSave.hasOwnProperty("offerInProfile")) {
        audienceForSave.offerInProfile = [];
    }
    if (!audienceForSave.hasOwnProperty("offerNotInProfile")) {
        audienceForSave.offerNotInProfile = [];
    }
}

export function prepareCohortsForSave(cohorts) {
    let cohortsForSave = {};
    cohorts.forEach(cohort => {
        cohortsForSave[cohort.id] = {
            id: cohort.id,
            active: cohort.active,
            creationDate: cohort.creationDate,
            limit: parseInt(cohort.limit === '0') ? -1 : parseInt(cohort.limit),
            weight: parseInt(cohort.weight),
            parameters: JSON.parse(cohort.parameters),
            size: parseInt(cohort.size),
            userGroups:cohort.userGroup ? cohort.userGroup : []
        }
    })
    return cohortsForSave;

}

function _prepareOffers (audienceForSave, parameterSet) {
    if (parameterSet.parameterData?.allowedRules?.includes('OFFER_IN_PROFILE')) {
        audienceForSave.offerInProfile = {
            offersInProfile: {
                parameter: 'offers_in_profile',
                offerId: parameterSet.paramValue
            }
        }
    } else if (parameterSet.parameterData?.allowedRules?.includes('OFFER_NOT_IN_PROFILE')) {
        audienceForSave.offerNotInProfile = {
            offersNotInProfile: {
                parameter: 'offers_not_in_profile',
                offerId: parameterSet.paramValue
            }
        }
    } else if (parameterSet.parameterData?.allowedRules?.includes('PROFILE_OFFSET')) {
        let paramName = parameterSet.parameterData.parameterName
        audienceForSave.profileOffset[paramName] = {
            offerId: parameterSet.offerId,
            offset: parameterSet.offsetValue,
            parameter: paramName
        }
    }
    _checkOffersInAudience(audienceForSave)
}

function _checkOffersInAudience(audienceForSave) {

    if (!audienceForSave.hasOwnProperty("profileOffset")) {
        audienceForSave.profileOffset = [];
    }
    if (!audienceForSave.hasOwnProperty("offerInProfile")) {
        audienceForSave.offerInProfile = [];
    }
    if (!audienceForSave.hasOwnProperty("offerNotInProfile")) {
        audienceForSave.offerNotInProfile = [];
    }
}