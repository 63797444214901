import {
    Autocomplete, Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    FormHelperText,
    Grid,
    IconButton, InputAdornment, InputLabel, OutlinedInput,
    TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {getEnvName, isEmpty} from "../Utils/CommonUtils";
import {Alert, LoadingButton} from "@mui/lab";
import {useContext, useState} from "react";
import {useGetAllBundlesQuery} from "../../core/reducers/campaign";
import {useCreateVariantTestMutation} from "../../core/reducers/variant";
import {SnackBarContext} from "../../core/context/snackBarProvider";
import {Box} from "@mui/system";

export const NewVariantTestModal = ({openCreate, setOpenCreate, base}) => {
    const [createVariant, createVariantResponse] = useCreateVariantTestMutation();

    console.log('createVariantResponse')
    console.log(createVariantResponse)

    const useSnackBars = () => useContext(SnackBarContext);
    const {addAlert} = useSnackBars();

    const typeOptions = ["universal_offer"]; // Пример констант для type
    const {
        data: bundles,
    } = useGetAllBundlesQuery('liveops');
    const bundleOptions = ["Bundle1", "Bundle2", "Bundle3"]; // Пример констант для bundle


    const [newVariant, setNewVariant] = useState({
        id: "",
        docLink: "",
        type: "universal_offer",
        bundleId: base.bundleId

    });

    const handleChange = (field, value) => {
        let isValid = true;

        if (field === 'docLink') {
            isValid = value==''||validateWikiLink(value);
        }
        else if (field === 'name') {
            isValid = validateName(value);
        } else {
            isValid = !isEmpty(value);
        }

        setNewVariant(prev => ({
            ...prev,
            [field]: value
        }));

        setErrors(prev => ({
            ...prev,
            [field]: !isValid
        }));
    }


    const isFormValid = () => {
        return !Object.values(errors).some(error => error) &&
            !isEmpty(newVariant.id);
    }


    const [errors, setErrors] = useState({
        id: true,
        name: false,
        docLink: false
    });


    const validateBalanceLink = (link) => {
        const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/.+/;
        return regex.test(link);
    };


    const validateWikiLink = (link) => {
        const regex = /^https:\/\/belka-games\.atlassian\.net\/wiki\/.+/;
        return link == '' || regex.test(link);
    };

    const validateName = (name) => {
        const regex = /^[A-Za-z0-9_]+$/;
        return regex.test(name);
    };

    const handleCreate = async () => {
        let variant_data = {...newVariant}
        variant_data.id = variant_data.bundleId + '_' + variant_data.id
        const response = await createVariant({
            body: variant_data,
            env: 'liveops'
        })

        console.log('response')
        console.log(response)
        console.log('Error ' + response?.error?.status + ' ' + response?.error?.data?.message)

        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
            addAlert({text: 'Variant created', type: 'success'})
            setOpenCreate(false)
        } else {
            addAlert({text: 'Error ' + response?.error?.status + ' ' + response?.error?.data?.message, type: 'error'})
        }
    }

    return <Dialog open={openCreate} onClose={() => setOpenCreate(false)} >
        <DialogTitle>Create Test Variant</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={() => setOpenCreate(false)}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon/>
        </IconButton>
        <DialogContent>
            {createVariantResponse.status=='rejected'&&<Alert sx={{mb:2}} severity={"error"}>{createVariantResponse.error?.data?.message}</Alert>}

            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <FormControl fullWidth >
                        <InputLabel htmlFor="outlined-adornment-amount">Name</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment sx={{pr:0, mr:0}} position="start">{base.bundleId}_</InputAdornment>}
                            label="Name"

                            onChange={(e) => handleChange('id', e.target.value)}
                            required={true}

                            error={errors.id}
                            helperText={errors.id ? "Please enter a valid  name" : ""}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required={false}

                        name="docLink"
                        label="Document Link"
                        value={newVariant.docLink}
                        onChange={(e) => handleChange('docLink', e.target.value)}
                        fullWidth
                        error={errors.docLink}
                        helperText={errors.docLink ? "Please enter a valid https://belka-games.atlassian.net/wiki link" : ""}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenCreate(false)} variant={'outlined'}>
                Cancel
            </Button>
            <LoadingButton loading={createVariantResponse.isLoading} onClick={handleCreate} variant={'contained'} disabled={!isFormValid()}>
                Create
            </LoadingButton>
        </DialogActions>
    </Dialog>
}