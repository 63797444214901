import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../Loading";
import {VariantItem} from "./VariantItem";
import {useVariantBaseQuery} from "../../core/reducers/variant";

export const BaseVariants = ({filterText, filterType, setOpenCreate}) => {

    const {
        data: variantsBase,
        isLoading,
        isError,
        error
    } = useVariantBaseQuery({variant:'base',type: filterType},{pollingInterval: 5000})


    return <>
        <div className="campaign-group">

            <div className="campaign-type-title">
                <span>Base variants</span>
                <Button size={"small"} sx={{ml:2,minWidth:'0'}} variant={"outlined"} onClick={()=>setOpenCreate(true)}><AddIcon /></Button>

            </div>

            <div className="campaigns-list variants">
                <Loading isLoading={isLoading} isError={isError} error={error}>
                    {variantsBase!=undefined && variantsBase.filter(item => filterText==''||item.bundleId.indexOf(filterText)>=0).map((item) => (<VariantItem variant={"base"} item={item} />))}
                </Loading>

            </div>
        </div>
    </>

}